<template>
  <div id="search-menu-page" class="h-100 d-flex venues-list flex-column py-3">
    <b-container>
      <p class="text-white">
        <small>
          Choisissez votre "ville" ou votre "adresse, ville" ou votre "code
          postal" ci-dessus puis renseignez le plat recherché. Vos résultats de
          recherche apparaîtront
          <span class="highlight">surlignés en jaune</span> suivant qu'ils se
          trouvent dans le Menu Du Jour ou la Carte des plats
        </small>
      </p>
    </b-container>
    <b-form id="search-menu" class="p-3" @submit.prevent="reset">
      <b-container>
        <b-input-group>
          <b-input
            ref="inputSearchMenu"
            v-model="search"
            size="lg"
            placeholder="Que voulez-vous manger ?"
            @focus="handleBtn()"
            @click="handleBtn()"
          ></b-input>
          <b-input-group-append>
            <b-button variant="white" @click="resetSearch()">
              <font-awesome-icon icon="times" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div
          v-if="showBtn"
          id="formSubmitBtn"
          class="d-flex justify-content-center mt-3"
        >
          <b-button
            variant="success"
            block
            class="w-full font-weight-bold text-uppercase"
            type="submit"
            >Rechercher</b-button
          >
        </div>
      </b-container>
    </b-form>

    <b-row v-show="search.length" no-gutters class="venues-list pt-3">
      <b-col v-for="menu in searchMenus" :key="menu.id" cols="12">
        <b-container class="position-relative">
          <b-card class="venue-card mb-3">
            <template #header>
              <div class="p-3">
                <div class="position-relative">
                  <h1>{{ menu.venue.name }}</h1>
                  <h2>{{ menu.venue.zipcode }} {{ menu.venue.city }}</h2>
                </div>
              </div>
              <div class="subtitle p-2">
                <div class="d-flex justify-content-between">
                  <span>Extrait de la carte des plats</span>
                  <span>{{ menu.venue.distance / 1000 }}kms</span>
                </div>
              </div>
            </template>
            <h6 v-html="matchedDish(menu.name)" class="text-center mb-4"></h6>
            <div v-for="(dish, i) in menu.dishes" :key="i">
              <div v-html="matchedDish(dish.name)"></div>
            </div>

            <template #footer>
              <div class="p-2 text-center">
                <b-link
                  :to="{
                    path: 'venue/' + menu.venue.id,
                    query: { search: search }
                  }"
                  class="text-uppercase"
                  append
                  :disabled="$route.params.id != null"
                  style="z-index: 11"
                >
                  CLIQUEZ ICI POUR VOIR LA FICHE DE CE RESTAURANT ET SA CARTE
                  DES PLATS COMPLÈTE
                </b-link>
              </div>
            </template>
          </b-card>
        </b-container>
      </b-col>
    </b-row>
    <portal to="content">
      <transition name="swipe">
        <router-view />
      </transition>
    </portal>
    <fit-height-loader
      v-if="$apollo.queries.searchMenus.loading"
      class="position-absolute"
      show
      style="z-index: 200; left: 0; background-color: rgba(255, 255, 255, 0.5)"
    />
  </div>
</template>
<script>
import { mapState } from "vuex"
import SEARCH_MENUS from "@/graphql/SearchMenus.gql"
import FitHeightLoader from "@/components/FitHeightLoader"
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql"
import VenueListItem from "@//components/VenueListItem"
import PermanentMenu from "../components/PermanentMenu.vue"
import { reservationHighlight } from "@/utils"
export default {
  components: {
    FitHeightLoader,
    VenueListItem,
    PermanentMenu
  },
  data() {
    return {
      search: this.$route.query.search || "",
      previousRadius: this.radius,
      targetTop: null,
      showBtn: true,
      debug: {}
    }
  },
  apollo: {
    searchMenus: {
      query: SEARCH_MENUS,
      skip: true
    },
    advertiserDay: {
      query: ADVERTISER_DAY
    }
  },
  computed: {
    ...mapState({
      coordinate: state => state.coordinate,
      radius: state => state.radius,
      place: state => state.city
    })
  },
  watch: {
    place: {
      handler: function () {
        if (this.place) {
          this.reset()
        }
      }
    },
    radius: function () {
      if (this.place) {
        this.reset()
      }
    }
  },
  created() {
    this.previousRadius = this.radius
    this.$store.commit("radius", this.$route.query.radius || 100)
  },
  mounted() {
    const target = document.getElementById("content")
    target.addEventListener("scroll", this.onScroll)
    this.targetTop =
      document.getElementById("search-menu").getBoundingClientRect().top - 52
  },
  beforeDestroy() {
    this.$store.commit("radius", this.previousRadius)
    const target = document.getElementById("content")
    target.classList.remove("sticky")
    target.removeEventListener("scroll", this.onScroll)
    target.querySelector(" .venues-list .venues-list ").paddingTop = ""
  },
  methods: {
    onScroll(evt) {
      const target = document.getElementById("content")
      if (target.scrollTop > this.targetTop) {
        target.classList.add("sticky")
        target.querySelector(
          " .venues-list .venues-list "
        ).style.paddingTop = `${this.targetTop}px`
        this.showBtn = false
      } else {
        target.classList.remove("sticky")
        target.querySelector(" .venues-list .venues-list ").paddingTop = ""
        this.showBtn = true
      }
    },
    reset() {
      if (this.search.length >= 2) {
        const target = document.getElementById("content")
        target.scrollTop = this.targetTop + 1
        this.$apollo.queries.searchMenus.setVariables(this.variables())
        this.$apollo.queries.searchMenus.skip = false
        this.$apollo.queries.searchMenus.refetch().finally(() => {
          this.$refs["inputSearchMenu"].blur()
        })
      } else {
        this.searchMenus = []
      }
    },
    variables() {
      const vars = {
        coordinate: {
          lat: this.coordinate.lat,
          lng: this.coordinate.lng
        },
        radius: parseFloat(this.radius),
        search: this.search,
        place: this.place,
        source: this.$route.query.source || "webapp"
      }
      return vars
    },
    hasActiveMenu(venue) {
      return this.activeMenus(venue).length
    },
    hasPermanentMenu(venue) {
      return this.permanentMenus(venue).length
    },
    activeMenus(venue) {
      return venue.menus.filter(m => m.publishedAt !== null)
    },
    permanentMenus(venue) {
      return venue.menus.filter(m => m.publishedAt === null)
    },
    showPermanentMenus(venue) {
      this.currentPermanentMenus = this.permanentMenus(venue)
      this.currentVenue = venue
      this.$nextTick(() => {
        this.$bvModal.show("menus-modal")
      })
    },
    handleBtn() {
      const target = document.getElementById("content")
      if (target.classList.contains("sticky")) {
        this.showBtn = true
      }
    },
    onSwipeTop() {
      let elt = document.getElementById("menus-modal___BV_modal_body_")
      if (elt.scrollTop + elt.offsetHeight > elt.scrollHeight) {
        this.$bvModal.hide("menus-modal")
      }
    },
    resetSearch() {
      this.search = ""
      this.$refs["inputSearchMenu"].focus()
    },
    matchedDish(dish) {
      return reservationHighlight(this.search, dish)
    }
  }
}
</script>
